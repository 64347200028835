import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {HomePage} from '../../modules/main/HomePage'

const HomePageWrapper: FC = () => {
  // const intl = useIntl()
  // return (
  //   <>
  //     <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.HOMEPAGE'})}</PageTitle>
  //     <HomePage />
  //   </>
  // )
  window.location.href = 'https://www.dedicationagents.com/';
  return null;
}

export {HomePageWrapper}
